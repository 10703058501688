import React from 'react'
import Navbar from '../Navbar'

const SmallHeader = ({ title, subtitle, children }) => (
  <div className="small-header text-center">
    <div className="header-overlay">
      <Navbar />
      <div className="header-content">
        {title && <h1 className="title">{title}</h1>}
        {subtitle && <h2 className="subtitle">{subtitle}</h2>}
        {children}
      </div>
    </div>
  </div>
)

export default SmallHeader
