import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import { NavbarToggler, Collapse } from 'reactstrap'
import Icon from '../Icon'
import './Navbar.scss'
import whiteLogo from '../../images/logo-white-80x.png'
import darkLogo from '../../images/logo-dark-80x.png'

class Navbar extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      navColor: 'dark',
      isOpen: false,
    }

    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.updateNavbarClass = this.updateNavbarClass.bind(this)
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateNavbarClass)
    window.addEventListener('resize', this.updateNavbarClass)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateNavbarClass)
    window.removeEventListener('resize', this.updateNavbarClass)
  }

  updateNavbarClass() {
    const { navColor } = this.state
    if (window.scrollY < 50) {
      if (navColor !== 'dark') {
        this.setState({ navColor: 'dark' })
      }
    } else {
      if (navColor !== 'light') {
        this.setState({ navColor: 'light' })
      }
    }
  }

  render() {
    const { navColor } = this.state
    const logoSrc = navColor === 'dark' ? whiteLogo : darkLogo

    return (
      <div
        className={`nav navbar navbar-expand-md navbar-${navColor} fixed-top`}
        data-scroll-header=""
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logoSrc} style={{ height: '40px' }} alt="Brentiv" />
          </Link>
          <NavbarToggler
            onClick={this.toggleCollapse}
            aria-expanded="false"
            aria-label="Toggle navigation"
          />

          <Collapse isOpen={this.state.isOpen} navbar>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/features" className="nav-link">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/pricing" className="nav-link">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact us
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link d-flex align-items-center justify-content-center"
                  href="https://login.brentiv.com"
                >
                  <Icon
                    name="log-in"
                    size={14}
                    className="mr-2"
                    style={{ marginBottom: '2px' }}
                  />
                  Login
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link btn btn-success btn-signup ml-2"
                  href="https://signup.brentiv.com"
                >
                  Free trial
                </a>
              </li>
            </ul>
          </Collapse>
        </div>
      </div>
    )
  }
}

export default Navbar
