import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import siteConfig from '../../data/config.json'

const SEO = ({ title, description, pagePath }) => {
  const pageUrl = `${siteConfig.url}${pagePath}`
  const omniGraphImageUrl = `${siteConfig.omniGraphImageUrl}?v=${Date.now()}`

  return (
    <Helmet>
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      {siteConfig.social.twitter && siteConfig.social.twitter.username && (
        <meta
          name="twitter:site"
          content={`@${siteConfig.social.twitter.username}`}
        />
      )}

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteConfig.companyName} />
      <meta property="og:image" content={omniGraphImageUrl} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pagePath: PropTypes.string.isRequired,
}

export default SEO
