import React from 'react'
import { Link } from 'gatsby'
import ContactSection from '../ContactSection'
import config from '../../data/config.json'

const Footer = ({ withContact }) => (
  <div>
    {withContact && <ContactSection />}
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="brand">
              © {new Date().getFullYear()} {config.companyName}
            </div>
          </div>
          <div className="col-sm-8 d-flex justify-content-sm-end">
            <div className="links">
              <Link to="/terms" className="link">
                Terms of Service
              </Link>
              <Link to="/privacy" className="link">
                Privacy Policy
              </Link>
              <Link to="/security" className="link">
                Security
              </Link>
              {config.email && (
                <a className="link" href={`mailto:${config.email}`}>
                  Contact Us
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
