import React, { PureComponent } from 'react'

class CookieConsent extends PureComponent {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      require('cookieconsent')
      require('./CookieConsent.scss')

      window.cookieconsent.initialise({
        location: true,
        palette: {
          popup: {
            background: '#f8f7fa',
          },
          button: {
            background: '#5764c6',
          },
        },
        content: {
          link: 'Privacy Policy',
          href: 'https://brentiv.com/privacy/',
        },
      })
    }
  }

  render() {
    return <></>
  }
}

export default CookieConsent
