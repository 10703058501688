import React from 'react'
import Icon from '../Icon'
import config from '../../data/config.json'

const ContactSection = () => (
  <div id="contact" className="contact-block">
    <div className="container">
      <div className="row">
        <div className="col-sm-5">
          <strong>Let's talk</strong> about how {config.companyName} can help
          you to rent products without any hassle.
          <ul className="address list-unstyled">
            {config.email && (
              <li className="address-item">
                <Icon className="icon" size="20" name="mail" />
                <a href={`mailto:${config.email}`}>{config.email}</a>
              </li>
            )}
            {config.social.facebook && config.social.facebook.url && (
              <li className="address-item">
                <Icon className="icon" size="20" name="facebook" />
                <a
                  href={config.social.facebook.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
            )}
            {config.social.twitter && config.social.twitter.username && (
              <li className="address-item">
                <Icon className="icon" size="20" name="twitter" />
                <a
                  href={`https://twitter.com/${config.social.twitter.username}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Twitter
                </a>
              </li>
            )}
            {config.social.instagram && config.social.instagram.username && (
              <li className="address-item">
                <Icon className="icon" size="20" name="instagram" />
                <a
                  href={`https://www.instagram.com/${
                    config.social.instagram.username
                  }`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="ml-auto col-sm-6">
          <div className="title">Contact us</div>
          <form action="https://formspree.io/support@brentiv.com" method="POST">
            <input type="hidden" name="_next" value="https://brentiv.com" />
            <input type="text" name="_gotcha" style={{ display: 'none' }} />
            <div className="form-group">
              <label className="sr-only" htmlFor="contactName">
                Name
              </label>
              <input
                id="contactName"
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                required
              />
            </div>
            <div className="form-group">
              <label className="sr-only" htmlFor="contactEmail">
                Email
              </label>
              <input
                id="contactEmail"
                className="form-control"
                type="email"
                name="_replyto"
                placeholder="Email address"
                required
              />
            </div>
            <div className="form-group">
              <label className="sr-only" htmlFor="contactMessage">
                Message
              </label>
              <textarea
                id="contactMessage"
                className="form-control"
                name="message"
                placeholder="Message"
                required
              />
            </div>
            <button className="btn btn-default btn-submit" type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default ContactSection
