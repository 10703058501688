import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Footer from '../components/Footer'

const SecurityPage = () => (
  <Layout path="/security" title="Security - Brentiv - Rental Software">
    <div className="legal-page security-page">
      <SmallHeader title="Security" />
      <div className="row-spaced legal-page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="container-padded">
                <h2 className="title">Security Assessments and Compliance</h2>
                <h3 className="subtitle">Data Centers</h3>
                <p>
                  Brentiv’s physical infrastructure is hosted and managed within
                  Amazon’s secure data centers and utilize the Amazon Web
                  Service (AWS) technology. Amazon continually manages risk and
                  undergoes recurring assessments to ensure compliance with
                  industry standards. Amazon’s data center operations have been
                  accredited under:
                </p>
                <ul>
                  <li>ISO 27001</li>
                  <li>
                    SOC 1 and SOC 2/SSAE 16/ISAE 3402 (Previously SAS 70 Type
                    II)
                  </li>
                  <li>PCI Level 1</li>
                  <li>FISMA Moderate</li>
                  <li>Sarbanes-Oxley (SOX)</li>
                  <li>PCI</li>
                </ul>

                <h2 className="subtitle">Physical Security</h2>
                <p>
                  Brentiv utilizes ISO 27001 and FISMA certified data centers
                  managed by Amazon. Amazon has many years of experience in
                  designing, constructing, and operating large-scale data
                  centers. This experience has been applied to the AWS platform
                  and infrastructure. AWS data centers are housed in nondescript
                  facilities, and critical facilities have extensive setback and
                  military grade perimeter control berms as well as other
                  natural boundary protection. Physical access is strictly
                  controlled both at the perimeter and at building ingress
                  points by professional security staff utilizing video
                  surveillance, state of the art intrusion detection systems,
                  and other electronic means. Authorized staff must pass
                  two-factor authentication no fewer than three times to access
                  data center floors. All visitors and contractors are required
                  to present identification and are signed in and continually
                  escorted by authorized staff.
                </p>
                <p>
                  Amazon only provides data center access and information to
                  employees who have a legitimate business need for such
                  privileges. When an employee no longer has a business need for
                  these privileges, his or her access is immediately revoked,
                  even if they continue to be an employee of Amazon or Amazon
                  Web Services. All physical and electronic access to data
                  centers by Amazon employees is logged and audited routinely.
                </p>
                <p>
                  For additional information see:{' '}
                  <a href="https://aws.amazon.com/security">
                    https://aws.amazon.com/security
                  </a>
                </p>
              </div>

              <div className="container-padded">
                <h2 className="title">Environmental Safeguards</h2>

                <h3 className="subtitle">Fire Detection and Suppression</h3>
                <p>
                  Automatic fire detection and suppression equipment has been
                  installed to reduce risk. The fire detection system utilizes
                  smoke detection sensors in all data center environments,
                  mechanical and electrical infrastructure spaces, chiller rooms
                  and generator equipment rooms. These areas are protected by
                  either wet-pipe, double-interlocked pre-action, or gaseous
                  sprinkler systems.
                </p>

                <h3 className="subtitle">Power</h3>
                <p>
                  The data center electrical power systems are designed to be
                  fully redundant and maintainable without impact to operations,
                  24 hours a day, and seven days a week. Uninterruptible Power
                  Supply (UPS) units provide back-up power in the event of an
                  electrical failure for critical and essential loads in the
                  facility. Data centers use generators to provide backup power
                  for the entire facility.
                </p>

                <h3 className="subtitle">Climate and Temperature Control</h3>
                <p>
                  Climate control is required to maintain a constant operating
                  temperature for servers and other hardware, which prevents
                  overheating and reduces the possibility of service outages.
                  Data centers are conditioned to maintain atmospheric
                  conditions at optimal levels. Monitoring systems and data
                  center personnel ensure temperature and humidity are at the
                  appropriate levels.
                </p>

                <h3 className="subtitle">Management</h3>
                <p>
                  Data center staff monitor electrical, mechanical and life
                  support systems and equipment so issues are immediately
                  identified. Preventative maintenance is performed to maintain
                  the continued operability of equipment.
                </p>
                <p>
                  For additional information see:{' '}
                  <a href="https://aws.amazon.com/security">
                    https://aws.amazon.com/security
                  </a>
                </p>
              </div>

              <div className="container-padded">
                <h2 className="title">Network Security</h2>

                <h3 className="subtitle">Firewalls</h3>
                <p>
                  Firewalls are utilized to restrict access to systems from
                  external networks and between systems internally. By default
                  all access is denied and only explicitly allowed ports and
                  protocols are allowed based on business need. Each system is
                  assigned to a firewall security group based on the system’s
                  function. Security groups restrict access to only the ports
                  and protocols required for a system’s specific function to
                  mitigate risk.
                </p>
                <p>
                  Host-based firewalls restrict customer applications from
                  establishing localhost connections over the loopback network
                  interface to further isolate customer applications. Host-based
                  firewalls also provide the ability to further limit inbound
                  and outbound connections as needed.
                </p>

                <h3 className="subtitle">DDoS Mitigation</h3>
                <p>
                  Our infrastructure provides DDoS mitigation techniques
                  including TCP Syn cookies and connection rate limiting in
                  addition to maintaining multiple backbone connections and
                  internal bandwidth capacity that exceeds the Internet carrier
                  supplied bandwidth. We work closely with our providers to
                  quickly respond to events and enable advanced DDoS mitigation
                  controls when needed.
                </p>

                <h3 className="subtitle">Spoofing and Sniffing Protections</h3>
                <p>
                  Managed firewalls prevent IP, MAC, and ARP spoofing on the
                  network and between virtual hosts to ensure spoofing is not
                  possible. Packet sniffing is prevented by infrastructure
                  including the hypervisor which will not deliver traffic to an
                  interface which it is not addressed to. Brentiv utilizes
                  application isolation, operating system restrictions, and
                  encrypted connections to further ensure risk is mitigated at
                  all levels.
                </p>

                <h3 className="subtitle">Port Scanning</h3>
                <p>
                  Port scanning is prohibited and every reported instance is
                  investigated by our infrastructure provider. When port scans
                  are detected, they are stopped and access is blocked.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </Layout>
)

export default SecurityPage
