import React from 'react'
import feather from 'feather-icons'

const Icon = ({ name, size, ...rest }) => {
  if (!feather.icons[name]) return null
  const svg = feather.icons[name].toSvg({ width: size, height: size })

  return <span dangerouslySetInnerHTML={{ __html: svg }} {...rest} />
}

Icon.defaultProps = {
  size: 24,
}

export default Icon
