import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import CookieConsent from '../CookieConsent'
import SEO from '../SEO'
import config from '../../data/config.json'
import '../../scss/theme.scss'

const faviconVersion = Date.now()

const Layout = ({ title, path, description, children }) => (
  <div>
    <Helmet>
      <html lang="en" />
      <title>{title || config.title}</title>
      <meta name="description" content={description || config.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/apple-touch-icon.png?v=${faviconVersion}`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`/favicon-32x32.png?v=${faviconVersion}`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`/favicon-16x16.png?v=${faviconVersion}`}
        sizes="16x16"
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="mask-icon"
        href={`/safari-pinned-tab.svg?v=${faviconVersion}`}
        color="#3f5b8f"
      />
      <meta name="apple-mobile-web-app-title" content="Brentiv" />
      <meta name="application-name" content="Brentiv" />
      <meta name="theme-color" content="#3f5b8f" />
    </Helmet>
    <SEO
      title={title || config.title}
      description={description || config.description}
      pagePath={path}
    />

    {children}
    <CookieConsent />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Layout
